<!--
 * @Description: 合作模块
 * @Author: zhang zhen
 * @Date: 2023-02-15 12:06:28
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-29 16:05:46
 * @FilePath: /page-sass/src/views/requirement/requirementList/modules/cooperation.vue
-->
<template>
  <div class="recommendedInfo">
    <template v-if="info">
      <div class="status-bar">
        <img src="~@/assets/warning-bg.png" alt="" class="info-icon">
        <span>采购合作已建立，合作流程请在订单管理中操作</span>
      </div>
      <div class="recommendedInfo-card">
        <div class="top-box">
          <span>订单编号：{{ info.orderNo || '-' }}</span><span>创建时间：{{ companyInfo.createTime || '-' }}</span>
        </div>
        <a-row :gutter="10" type="flex" justify="space-between">
          <a-col :span="20" class="info-left">
            <div class="top-info">
              <!-- <img src="/cover_base.png" alt="" class="cover"> -->
              <img :src="companyInfo.picture || '/cover_base.png'" alt="" class="cover" />
              <div class="info-view">
                <div class="main-title">
                  <span class="label">{{ companyInfo.businessName || '-' }}</span>
                  <img src="/passTag.png" alt="" class="tag">
                </div>
                <div class="location-info">
                  <span style="margin-right: 13px;">{{ companyInfo.companyTypeDictName || '-' }} | {{
      companyInfo.industryDictName || '-'
    }}</span>
                  <img src="/location.png" alt="" class="location">
                  <span class="area-name">{{ companyInfo.cityName || '' }} {{ companyInfo.areaName || '' }}</span>
                </div>
                <div class="tags-list">
                  <div class="tags-list-item" v-for="row in companyInfo.leftTagList" :key="row">
                    <span>
                      <a-tooltip slot="suffix" :title="row">
                        {{ row }}
                      </a-tooltip>
                    </span>
                  </div>
                </div>
                <div class="info-box-area">
                  <div class="counter">
                    <span class="label">主营包装:</span>
                    <span class="category" v-for="item in companyInfo.mainPackagingDictNameList" :key="item">{{ item
                      }}</span>
                  </div>
                  <a class="view-info" :href="`/companyInfoPage?id=${companyInfo.businessId}`">查看企业 ></a>
                </div>

              </div>
            </div>
          </a-col>
          <a-col :span="4" class="rightCard">
            <a-button type="primary" @click="handleJump">
              <img src="/orderIcon.png" alt="" class="invited-icon">
              <span>查看订单</span>
            </a-button>
            <a-button type="primary" ghost @click="handleChat(companyInfo)">
              <img src="~@/assets/message-3-line.png" alt="" class="invited-icon">
              <span>在线咨询</span>
            </a-button>
          </a-col>
        </a-row>
      </div>
    </template>
    <EmptyArea v-else description="暂无合作供应商记录" />
  </div>
</template>

<script>
import { getAction, postQueryAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import ChatMixin from '@/mixins/chatMixins'

export default {
  name: 'cooperation',
  components: {
    EmptyArea
  },
  mixins: [ChatMixin],
  props: {
    purchaseId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      info: {},
      businessDTO: {},
      companyInfo: {
        leftTagList: [],
        mainPackagingDictNameList: []
      },
    }
  },
  methods: {
    // businessId
    handleLoadInfo() {
      getAction('/quote/confirm/get', {
        purchaseId: this.purchaseId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.info = data
          if (!data || !data.businessDTO) return;
          if (data.businessDTO) {
            const { capacity } = data.businessDTO
            this.companyInfo = data.businessDTO;
            this.companyInfo.mainPackagingDictNameList = []
            if (capacity && capacity.fromingDictName) {
              this.companyInfo.mainPackagingDictNameList = capacity.fromingDictName.split(',');
            }
            if (capacity) {
              const { yearTurnoverDictName, minOrderCountDictName, sampleTestDictName, authMethodDictName, printingMethodDictName, productPackageDictName, purchasingMethodDictName } = capacity;
              let sampleTestDictNameList = [], authMethodDictNameList = [], printingMethodDictNameList = [];
              if (sampleTestDictName) {
                sampleTestDictNameList = sampleTestDictName.split(',').map(item => `支持${item}`);
              }
              if (authMethodDictName) {
                authMethodDictNameList = authMethodDictName.split(',').map(item => `支持${item}`);
              }
              if (printingMethodDictName) {
                printingMethodDictNameList = printingMethodDictName.split(',').map(item => `支持${item}`);
              }
              let tagList = ['年营业额：' + yearTurnoverDictName, '最小下单量：' + minOrderCountDictName, ...sampleTestDictNameList, ...authMethodDictNameList, ...printingMethodDictNameList, productPackageDictName, purchasingMethodDictName].filter(item => !!item);
              this.companyInfo.leftTagList = tagList.slice(0, 5);
            }
          }
        }
      })
    },
    // 查看订单详情
    handleJump() {
      this.$router.push(
        `/orderManagement/orderDetailsForUser?orderNo=${this.info.orderNo}&purchaseId=${this.purchaseId}`
      )
    }
  }
}
</script>

<style lang="less" scoped>
.recommendedInfo {
  &-memo {
    color: #333;
    margin-bottom: 8px;
  }

  &-card {
    background: #f7f8fa;
    padding: 25px;
    margin-bottom: 25px;

    // height: 232px;
    .top-box {
      display: flex;
      align-items: center;
      grid-gap: 0 24px;
      padding-bottom: 8px;
      border-bottom: 1px solid #EFEFEF;
      margin-bottom: 15px;

      span {
        line-height: 18px;
        color: #666666;
        font-size: 12px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .top-info {
      width: 100%;
      .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);
      // margin-bottom: 8px;
      padding-right: 26px;

      .cover {
        width: 126px;
        height: 126px;
        border-radius: 4px;
        border: 1px solid #EFEFEF;
      }

      .info-box {
        margin-left: 15px;
        flex: 1 0 0;
        margin-right: 40px;

        .title {
          color: #000;
          font-size: 16px;
          margin-bottom: 20px;
          font-weight: 500;
        }
      }
    }

    .labelBox {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      .label {
        display: inline-block;
        // width: 36px;
        line-height: 19px;
        color: #000000A6;
        // margin-right: 8px;
        font-size: 12px;
      }

      .label-value {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: #000000A6;
      }

      .label-tag {
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 19px;
        // padding: 4px 0;
        // width: 119px;
        .text-emphasis();

      }
    }
  }

  .info-left {
    width: 84.533334%;
  }

  .rightCard {
    border-left: 1px solid #EFEFEF;
    padding-left: 33px !important;
    height: 88px;
    .flexLayout(@direction: column; @justifyContent: space-between; @alignItem: flex-end);
    width: 15.466666%;

    .ant-btn {
      width: 110px;
      height: 36px;
      line-height: 36px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;

      .invited-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }

      &.last-btn {
        color: #595959;

        img.icon {
          margin-right: 6px;
        }
      }

      &.invited-btn {
        color: #8C8C8C;
        font-size: 14px;
        background-color: #E8E9EB;
        border-color: #E8E9EB;
        cursor: not-allowed;

      }
    }
  }
}

.info-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-info-box {
  width: 160px;

  &:not(:last-of-type) {
    margin-right: 25px;
  }

  .cover-img {
    width: 160px;
    height: 120px;
    border-radius: 8px;
  }

  .title {
    margin-top: 10px;
    color: #222222;
    .text-emphasis();

  }
}

::v-deep .ant-btn {
  font-size: 14px;
}

.status-bar {
  display: flex;
  align-items: center;
  background-color: #FF7D2F1A;
  height: 40px;
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  margin-bottom: 20px;

  .info-icon {
    width: 20px;
    margin-right: 8px;
  }

  span {
    color: #000000D9;
  }
}

@import '~@/styles/companyCard.less';
</style>
