<!--
 * @Description: 感兴趣推荐
 * @Author: zhang zhen
 * @Date: 2023-02-15 09:59:28
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-05-29 16:06:21
 * @FilePath: /page-sass/src/views/requirement/requirementList/modules/recommendedInfo.vue
-->
<template>
  <div class="recommendedInfo">
    <div class="status-bar">
      <img src="~@/assets/warning-bg.png" alt="" class="info-icon">
      <span>系统为你推荐以下优秀供应商，看看是否感兴趣吧！</span>
    </div>
    <div class="recommendedInfo-card" v-for="i in productList" :key="i.quoteId">
      <a-row :gutter="10" type="flex" justify="space-between">
        <a-col :span="20" class="info-left">
          <div class="top-info">
            <!-- <img src="/cover_base.png" alt="" class="cover"> -->
            <img :src="i.picture || '/cover_base.png'" alt="" class="cover" />
            <div class="info-view">
              <div class="main-title">
                <span class="label">{{ i.businessName || '-' }}</span>
                <img src="/passTag.png" alt="" class="tag">
              </div>
              <div class="location-info">
                <span style="margin-right: 13px;">{{ i.companyTypeDictName || '-' }} | {{ i.industryDictName || '-'
                  }}</span>
                <img src="/location.png" alt="" class="location">
                <span class="area-name">{{ i.cityName || '' }} {{ i.areaName || '' }}</span>
              </div>
              <div class="tags-list">
                <div class="tags-list-item" v-for="row in i.leftTagList" :key="row">
                  <span>
                    <a-tooltip slot="suffix" :title="row">
                      {{ row }}
                    </a-tooltip>
                  </span>
                </div>
              </div>
              <div class="info-box-area">
                <div class="counter">
                  <span class="label">主营包装:</span>
                  <span class="category" v-for="item in i.mainPackagingDictNameList" :key="item">{{ item }}</span>
                </div>
                <a class="view-info" :href="`/companyInfoPage?id=${i.businessId}`">查看企业 ></a>
              </div>

            </div>
          </div>
        </a-col>
        <a-col :span="4" class="rightCard">
          <a-button class="invited-btn" v-if="i.inviteStatus && i.inviteStatus == 1">
            <img src="/invited.png" alt="" class="invited-icon">
            <span>已邀请TA</span>
          </a-button>
          <a-button type="primary" @click="handlePointUser(i.businessId)" v-else>
            <img src="~@/assets/invite.png" alt="" class="invited-icon">
            <span>邀请TA</span>
          </a-button>
          <!-- <a-button type="primary" ghost @click="handleChat(i)">
            <img src="~@/assets/message-3-line.png" alt="" class="invited-icon">
            <span>在线咨询</span>
          </a-button> -->
          <a-button type="link" @click="handleNoInterests(i.businessId)" class="last-btn" v-if="i.inviteStatus != 1">
            <img src="~@/assets/notInt.png" alt="" class="invited-icon" />
            <span>不感兴趣</span>
          </a-button>
        </a-col>
      </a-row>
    </div>
    <div class="loadMore" v-if="pageNum < maxPage">
      <a-button type="primary" ghost @click="handleLoadMore">查看更多</a-button>
    </div>
    <EmptyArea v-if="isEmpty" :description="emptyMsg" />
  </div>
</template>

<script>
import { getAction, postAction } from '@/api/manage'
import EmptyArea from '@/components/plugins/EmptyArea.vue'
import { mapGetters } from 'vuex'
import ChatMixin from '@/mixins/chatMixins'
export default {
  name: 'recommendedInfo',
  components: {
    EmptyArea
  },
  mixins: [ChatMixin],
  props: {
    purchaseId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      emptyMsg: '暂无数据',
      isEmpty: true,
      productList: [],
      total: 0,
      pageNum: 1,
      maxPage: 1,
      businessName: null
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handlePointUser(businessId) {
      getAction('/invite', {
        purchaseId: this.purchaseId,
        businessId
      }).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          this.$router.replace('/requirement/requirementList')
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 不感兴趣 */
    handleNoInterests(businessId) {
      postAction('/quote/uninterest', {
        businessId,
        userId: this.setUserInfo().userId
      }).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(message)
          // 加载数据
          this.handleLoadInfo()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleSetSearch(businessName) {
      this.pageNum = 1
      this.productList = []
      this.businessName = businessName
      this.handleLoadInfo()
    },
    handleLoadMore() {
      if (this.pageNum < this.maxPage) {
        this.pageNum++
        this.handleLoadInfo()
      }

    },
    handleLoadInfo() {
      postAction('/business/query/recommend', {
        pagesSize: 20,
        pageNum: this.pageNum,
        purchaseId: this.purchaseId,
        businessName: this.businessName
      }).then(res => {
        const { success, data } = res
        if (success) {
          const { pages, list, total } = data
          let dataList = list.map(i => {
            const { capacity } = i;
            i.mainPackagingDictNameList = []
            if (capacity && capacity.fromingDictName) {
              i.mainPackagingDictNameList = capacity.fromingDictName.split(',');
            }
            if (capacity) {
              const { yearTurnoverDictName, minOrderCountDictName, sampleTestDictName, authMethodDictName, printingMethodDictName, productPackageDictName, purchasingMethodDictName } = capacity;
              let sampleTestDictNameList = [], authMethodDictNameList = [], printingMethodDictNameList = [];
              if (sampleTestDictName) {
                sampleTestDictNameList = sampleTestDictName.split(',').map(item => `支持${item}`);
              }
              if (authMethodDictName) {
                authMethodDictNameList = authMethodDictName.split(',').map(item => `支持${item}`);
              }
              if (printingMethodDictName) {
                printingMethodDictNameList = printingMethodDictName.split(',').map(item => `支持${item}`);
              }
              let tagList = ['年营业额：' + yearTurnoverDictName, '最小下单量：' + minOrderCountDictName, ...sampleTestDictNameList, ...authMethodDictNameList, ...printingMethodDictNameList, productPackageDictName, purchasingMethodDictName].filter(item => !!item);
              // let leftTagList = [], tagRight = null;
              // if (tagList.length > 5) {
              //     leftTagList = tagList.slice(0, 4);
              //     tagRight = tagList.slice(4).join('、')
              // } else {
              //     leftTagList = tagList
              // }
              i.leftTagList = tagList.slice(0, 5);
            }
            return i
          });
          this.productList = this.productList.concat(dataList)
          this.isEmpty = total == 0
          this.maxPage = pages
          this.emptyMsg = '暂无数据'
        } else {
          this.isEmpty = true
          this.emptyMsg = '暂无数据'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.recommendedInfo {
  &-memo {
    color: #333;
    margin-bottom: 8px;
  }

  &-card {
    background: #f7f8fa;
    padding: 25px;
    margin-bottom: 25px;
    // height: 232px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .top-info {
      width: 100%;
      .flexLayout(@justifyContent: flex-start; @alignItem: flex-start);
      // margin-bottom: 8px;
      padding-right: 26px;
      .cover {
        width: 126px;
        height: 126px;
        border-radius: 4px;
        border: 1px solid #EFEFEF;
      }

      .info-box {
        margin-left: 15px;
        flex: 1 0 0;
        margin-right: 40px;

        .title {
          color: #000;
          font-size: 16px;
          margin-bottom: 20px;
          font-weight: 500;
        }
      }
    }

    .labelBox {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;

      .label {
        display: inline-block;
        // width: 36px;
        line-height: 19px;
        color: #000000A6;
        // margin-right: 8px;
        font-size: 12px;
      }

      .label-value {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: left;
        color: #000000A6;
      }

      .label-tag {
        color: rgba(0, 0, 0, 0.65);
        text-align: center;
        line-height: 19px;
        // padding: 4px 0;
        // width: 119px;
        .text-emphasis();

      }
    }
  }
  .info-left {
    width: 84.533334%;
  }
  .rightCard {
    border-left: 1px solid #EFEFEF;
    padding-left: 33px!important;
    height: 88px;
    .flexLayout(@direction: column; @justifyContent: space-between; @alignItem: flex-end);
    width: 15.466666%;
    .ant-btn {
      width: 110px;
      height: 36px;
      line-height: 36px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;

      .invited-icon {
        width: 16px;
        height: 16px;
        margin-right: 6px;
      }

      &.last-btn {
        color: #595959;

        img.icon {
          margin-right: 6px;
        }
      }

      &.invited-btn {
        color: #8C8C8C;
        font-size: 14px;
        background-color: #E8E9EB;
        border-color: #E8E9EB;
        cursor: not-allowed;

      }
    }
  }
}

.info-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.card-info-box {
  width: 160px;

  &:not(:last-of-type) {
    margin-right: 25px;
  }

  .cover-img {
    width: 160px;
    height: 120px;
    border-radius: 8px;
  }

  .title {
    margin-top: 10px;
    color: #222222;
    .text-emphasis();

  }
}

::v-deep .ant-btn {
  font-size: 14px;
}

.status-bar {
  display: flex;
  align-items: center;
  background-color: #FF7D2F1A;
  height: 40px;
  border-radius: 4px;
  padding: 10px 16px 10px 16px;
  margin-bottom: 20px;

  .info-icon {
    width: 20px;
    margin-right: 8px;
  }

  span {
    color: #000000D9;
  }
}

@import '~@/styles/companyCard.less';
</style>
